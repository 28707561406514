<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card p-fluid">
                <h4>
                    <strong>사용자 상세 정보</strong>
                </h4>
                <div class="p-field p-grid">
                    <label for="category_name_ko" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">닉네임:</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ name }}</p>
                    </div>
                </div>

                <div class="p-field p-grid">
                    <label for="material_name_ko" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">C코드:</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ customer_id }}</p>
                    </div>
                </div>

                <div class="p-d-flex p-jc-end p-ai-center">
                    <div>
                        <Button label="뒤로가기" iconPos="left" class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2" @click="$router.go(-1)"></Button>
                        <!-- <Button :label="$t('Confirm')" icon="pi pi-check" iconPos="left" class="p-button p-button-sm p-mr-2 p-mb-2"></Button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UserDetails from '../../service/API/UserDetails';
import utils from '@/service/utils';
import moment from 'moment';

export default {
    data() {
        return {
            selected: [],
            render: true,
            display: false,
            position: 'center',
            visibleLeft: false,
            visibleRight: false,
            visibleTop: false,
            visibleBottom: false,
            visibleFull: false,
            totalrecords: '',
            pagination: {
                current_page: 1,
                pagination_limit: 10,
                total_pages: 0,
                total_records: 0,
            },

            dropdownmembertype: [
                { name: '전체', code: 'all' },
                { name: '방장', code: 'owner' },
                { name: '참여자', code: 'member' },
            ],
            dropdownchatroomtype: [
                { name: '전체', code: 'all' },
                { name: '일반 채팅방', code: 'general' },
                { name: '위치기반 채팅방', code: 'location' },
                { name: '패키지', code: 'package' },
            ],
            dropdownprivacytype: [
                { name: '전체', code: 'all' },
                { name: 'Y', code: 'secret' },
                { name: 'N', code: 'everyone' },
            ],
            passcode: '',

            statusTrue: true,
            statusFalse: false,
            customer1: [],
            loading1: true,
            idFrozen: false,
            products: null,
            expandedRows: [],
            name: '',
            email: '',
            id: '',
            user_id: '',
            group_name: '',
            group_type: '',
            active_members: '',
            latest_message_time: '',
            member_type: '',
            chatroom_type: '',
            privacy_type: '',
            search_name: '',
            date_range: [],
            startDate: '',
            searchdate: '',
            endDate: '',
            searchenddate: '',
            create_date: '',
            customer_id:'',
        };
    },
    customerService: null,
    productService: null,
    created() {
        this.userDetails = new UserDetails();

        // this.search_name = localStorage.getItem('search_name');
        // this.member_type = localStorage.getItem('member_type');
        // this.chatroom_type = localStorage.getItem('chatroom_type');
        // this.privacy_type = localStorage.getItem('privacy_type');
        // this.searchdate = localStorage.getItem('startDate');
        // this.searchenddate = localStorage.getItem('endDate');
        // this.date_range = localStorage.getItem('daterange');

        // let sdate = localStorage.getItem('startDate');
        // let edate = localStorage.getItem('endDate');

        // if (sdate == '' && edate == '') {
        //     console.log(sdate, edate);
        // } else {
        //     this.searchdate = sdate;
        //     this.searchenddate = edate;

        //     this.date_range = [new Date(sdate), new Date(edate)];
        // }

        this.userdetaillist({
            id: this.$route.params.id,
            record_count: this.pagination.pagination_limit,
            page: 1,
            search_name: this.search_name,
            member_type: this.member_type,
            chatroom_type: this.chatroom_type,
            privacy_type: this.privacy_type,
            startDate: this.searchdate,
            endDate: this.searchenddate,
        });
    },
    mounted() {
        // localStorage.setItem('userid', this.$route.params.id);

        this.userDetails.getUserDetails(this.$route.params.id).then((res) => {
            // console.log(res);
            this.name = res.data.name;
            this.customer_id = res.data.customer_id;

            console.log(res);
        });
    },
    methods: {
        formatDate(value) {
            if (value) {
                return moment(String(value)).locale('ko').format('ll - LTS');
            }
        },
        userdetaillist(data) {
            this.loading1 = true;
            this.userDetails
                .getuserdetailList(data)
                .then((r) => {
                    this.loading1 = false;
                    this.customer1 = r.data.room_details;
                    this.totalrecords = r.data.total_records;
                    console.log(r);
                    // this.pagination_limit_options[this.pagination_limit_options.length-1].value = r.data.total_records
                    this.setPagination(r.data.current_page, r.data.total_pages === 0 ? 1 : r.data.total_pages, r.data.total_records);
                })
                .catch((err) => {
                    this.loading1 = false;
                    console.log(err);
                });
        },

        setPagination(current_page, total_pages, total_records) {
            this.pagination.current_page = current_page;
            this.pagination.total_pages = total_pages;
            this.pagination.total_records = total_records;
        },

        get_paginator_page(total, active) {
            return utils.paginate(total, active);
        },

        paginate(page) {
            this.loading1 = true;
            // var start_date = this.formatRangeDate(this.date_range.at(0));
            // var end_date = this.formatRangeDate(this.date_range.at(1));
            this.userDetails
                .getuserdetailList({
                    page: page,
                    record_count: this.pagination.pagination_limit,
                    search_name: this.search_name,
                    member_type: this.member_type,
                    chatroom_type: this.chatroom_type,
                    privacy_type: this.privacy_type,
                    startDate: this.searchdate,
                    endDate: this.searchenddate,
                })
                .then((res) => {
                    this.customer1 = res.data.room_details;
                    this.totalrecords = res.data.total_records;
                    console.log('paginate', this.customer1);
                    console.log('paginatetotal', this.totalrecords);
                    this.pagination.current_page = page;
                    this.loading1 = false;
                    // console.log(res.data);
                })
                .catch((err) => console.log(err));
        },
        resetuserdetail() {
            // localStorage.setItem('member_type', '');
            // localStorage.setItem('chatroom_type', '');
            // localStorage.setItem('privacy_type', '');
            // localStorage.setItem('startDate', '');
            // localStorage.setItem('endDate', '');
            // localStorage.setItem('daterange', '');
            (this.search_name = ''), (this.member_type = ''), (this.chatroom_type = ''), (this.privacy_type = ''), (this.date_range = ''), this.userdetaillist({ record_count: this.pagination.pagination_limit, page: this.pagination.current_page });
            window.location.reload();
        },

        formatRangeDate(date) {
            if (date === undefined || date === '') {
                return '';
            }
            return moment(new Date(date)).format('YYYY-MM-DD');
        },

        searchuserdetail() {
            if (this.search_name == '' && this.member_type == '' && this.chatroom_type == '' && this.privacy_type == '' && this.date_range === '') {
                this.$toast.add({ severity: 'error', summary: this.$t('에러 메시지'), detail: this.$t('하나 이상의 검색 값을 입력하십시오.'), life: 3000 });
            } else if (this.search_name !== '' || this.member_type !== '' || this.chatroom_type !== '' || this.privacy_type !== '' || this.date_range !== '') {
                if (this.date_range.at(0) != '') {
                    this.searchdate = this.formatRangeDate(this.date_range.at(0));
                } else {
                    this.searchdate = '';
                }

                if (this.date_range.at(1) != '') {
                    this.searchenddate = this.formatRangeDate(this.date_range.at(1));
                } else {
                    this.searchenddate = '';
                }

                // localStorage.setItem('search_name', this.search_name);
                // localStorage.setItem('member_type', this.member_type);
                // localStorage.setItem('chatroom_type', this.chatroom_type);
                // localStorage.setItem('privacy_type', this.privacy_type);
                // localStorage.setItem('startDate', this.searchdate);
                // localStorage.setItem('endDate', this.searchenddate);
                // localStorage.setItem('daterange', this.date_range);

                let searchParams = {
                    record_count: this.pagination.pagination_limit,
                    page: 1,
                    search_name: this.search_name,
                    member_type: this.member_type,
                    chatroom_type: this.chatroom_type,
                    privacy_type: this.privacy_type,
                    startDate: this.searchdate,
                    endDate: this.searchenddate,

                    // startDate: start_date,
                    // endDate: end_date,
                };
                console.log('searchParams', searchParams);
                this.customer1 = true;
                this.userDetails
                    .getuserdetailList(searchParams)
                    .then((res) => {
                        console.log('res', res);
                        this.customer1 = res.data.room_details;
                        console.log('searchPost', this.customer1);
                        this.setPagination(res.data.current_page, res.data.total_pages === 0 ? 1 : res.data.total_pages, res.data.total_records);
                        this.loading1 = false;
                    })
                    .catch(() => {
                        this.customer1 = [];
                        this.loading1 = false;
                    });
            } else {
                // var startDate = this.addDay(this.date_range.at(0));
                // var endDate = this.addDay(this.date_range.at(1));
                // console.log(startDate, endDate);
            }
        },

        today() {
            let today = new Date();
            let firstDate = new Date();
            firstDate.setDate(today.getDate());
            this.date_range = [firstDate, today];
            this.searchuserdetail();
        },
        lastweek() {
            let today = new Date();
            let firstDate = new Date();
            firstDate.setDate(today.getDate() - 7);
            this.date_range = [firstDate, today];
            this.searchuserdetail();
        },
        lastmonth() {
            let today = new Date();
            let firstDate = new Date();
            firstDate.setDate(today.getDate() - 31);
            this.date_range = [firstDate, today];
            this.searchuserdetail();
        },
        lastsixmonth() {
            let today = new Date();
            let firstDate = new Date();
            firstDate.setDate(today.getDate() - 182);
            this.date_range = [firstDate, today];
            this.searchuserdetail();
        },
    },
};
</script>
<style lang="scss" scoped>
.p-datatable-tbody {
    .p-button {
        white-space: nowrap;
    }
}
.p-fluid {
    .p-button {
        width: auto;
    }
}

p#round {
    border-radius: 12px;
    border: 1px solid black;
    width: 52px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.highlight:hover {
    border-bottom: 3px solid Blue;
}
</style>